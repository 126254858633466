import './rendering.css';
import { useEffect, useState } from "react"
import {useLocation} from "react-router-dom";
import { jsPDF } from "jspdf";
import html2pdf from 'html2pdf.js';

const axios = require("axios").default;



function ColorLabelIdomyaPage() {
	const [isLoaded, setIsLoaded] = useState(false);
	const [colorLabelData, setColorLabelData] = useState(null);
	const search = useLocation().search;

	function PrintPdf() {
	    var listPageHtml = document.querySelectorAll('.pdfcontainerPortrait')
		listPageHtml.forEach((data, index) => {
			const productCode =colorLabelData.docIds[index].docId
			var options = {
				filename: productCode +"_colorLabelIdomya.pdf",
				html2canvas: {
					useCORS: true,
					scale: 300/96,
				},
				jsPDF: {
					format:"a4",
					unit:"mm"
				}
			}
			html2pdf(data,options);

			console.log("print")
    	})
	}

	useEffect(() => {

		const currentIds = new URLSearchParams(search).get('ids');
		const currentToken = new URLSearchParams(search).get('token');
		const currenLocale = new URLSearchParams(search).get('locale');
		//const today =  new Date().toLocaleString("fr-FR");
		document.title = "Color Label Idomya"

		axios.get("/api/colorLabel",{params: {token: encodeURIComponent(currentToken), ids:currentIds, locale: currenLocale, brand:"IDOMYA_F-"}}).then(resp => {
			if (resp.status == 200) {
				console.log("success",resp.data)
				setColorLabelData(resp.data)
				
			} else {
				console.log("error",resp.data)
			}
			setIsLoaded(true)
		}).catch(error => {
			console.log(error)
      		setIsLoaded(true)
		})

	}, [])

	let dataPdf;

	
	if (isLoaded) {
		if (colorLabelData != null) {
			let listStructData =  []

      

    		const dataLength =colorLabelData.docIds.length
			for (let index = 0; index < dataLength; index++) {
				const element = colorLabelData.docIds[index];
				let last = false;
				if (index == dataLength -1) {
					console.log("last")
					last = true;
				} 

				listStructData.push(<ColorLabelIdomyaTemplate data ={element} lastElement ={last}/> )
				
			}

			dataPdf = listStructData
		} else {
			dataPdf = <div className='pdfLoader'> Erreur dans la récupération des données</div>
		}
	} else {
		dataPdf =<div className='pdfLoader'>Récupération des données...<br/>Veuillez Patienter</div>
	}

	return (
		<div style={{margin: 0, display:"flex", flexDirection:"column"}}>
			<style>
		        {`@media print {
	    		    .pdfnextPage {
	            		page-break-after: always;
	          		}	
  
	        		.pdfbuttonContainerCL {
	            		display: none !important;
	          		}
  
	        		.pdfcontainerBorder {
	            		border-bottom: 0px solid black !important;
	          		}
  
	          		.pdfcontainerPortrait {
	            		top: 0px !important;
	          		}
  
	          		@page {
			            margin: 0;
	    		        size: A4 portrait;
	          		}
  
			        body {
			            -webkit-print-color-adjust: exact;
	    			    color-adjust: exact;
	          		}
          		}`}
		      </style>
			<div style={{position: "inherit"}} class="pdfbuttonContainerCL">
      			<button class="pdfprintPDFButton" onClick={PrintPdf}>
        			Print Idomya Color Label
      			</button>
    		</div>
			{dataPdf}
			
		</div>
		
	)
}

function ColorLabelIdomyaTemplate(props) {
	const sheetData = props.data
	const isLast = props.lastElement
	console.log(sheetData)

	const nomProduitLines = sheetData.declinaison_nom_reecrit.split("\n").map(line => <p style={styleSheetProductPage.titleText}> {line.toUpperCase()} </p>)

	const infoBonusLines = sheetData.infoBonus.split("\n").map(line => <p style={styleSheetProductPage.dataDimension}> {line} <br/> </p>)


	useEffect(() => {
		console.log("parse data",isLast)
	},[])

	return (
		<div style={{ top:"0px", border:"0px", margin:"0", height:"296.8mm",width:"211mm"}} className={`pdfcontainerPortrait ${ isLast == true ? "" : "pdfnextPage" }`}>
			

			<div style={{  top: "0px", border: "0px", margin: "0", position : "absolute" }}>
				<img style={{ height: "296.8mm", width: "211mm" }} src={"/assets/images/fondColor/IDOMYA_FACTORY_OK.png"}/>
			</div>


			<div style={styleSheetProductPage.mainImgContainer}>
				<img style={{overflow:"hidden",width: "100%"}} src={sheetData.img1} />
			</div>
			{/* <div style={styleSheetProductPage.brandLogoContainer}>
				<img style={{width:"50mm"}}  src={sheetData.logobrand} />
			</div> */}
			<div style={styleSheetProductPage.titleContainer}>
				{nomProduitLines}
	        </div>
			
			<div style={styleSheetProductPage.img2Container}>
				<img style={{"border-top-right-radius": "20px", "border-bottom-right-radius": "20px",width: "100%"}} src={sheetData.imgMin} />
			</div>
			
			<div style={styleSheetProductPage.fakeShadow}>
				<img style={{width:"76mm", height:"12mm", "border-bottom-left-radius": "20px 30px"}} src={"/assets/images/fondColor/shadow8.png"} />
			</div>

			<div style={styleSheetProductPage.plusProduitContainer}>
				<div style={styleSheetProductPage.plusProduitTextContainer}>
					<p style={styleSheetProductPage.plusProduitText}>{sheetData.infoPlus1}</p>
				</div>
				<div style={styleSheetProductPage.plusProduitTextContainer}>
					<p style={styleSheetProductPage.plusProduitText}>{sheetData.infoPlus2}</p>
				</div>
				<div style={styleSheetProductPage.plusProduitTextContainer}>
					<p style={styleSheetProductPage.plusProduitText}>{sheetData.infoPlus3}</p>
				</div>
				
				
				
			</div>

			<div style={styleSheetProductPage.dimensionContainer}>
				<p style={styleSheetProductPage.titleDimension}>Dimensions</p>
				<p style={styleSheetProductPage.dataDimension}>(L) {sheetData.infoLength} x (l) {sheetData.infoWidth} x (H) {sheetData.infoHeight} cm</p>
				{infoBonusLines}
			</div>

			{/* <div style={{width:"1.5mm", height:"28mm",position:"absolute",bottom:"35mm", left:"156mm", backgroundColor:"rgb(49,50,49)"}}>

			</div> */}

			<div style={styleSheetProductPage.materiauxContainer}>
				<p style={styleSheetProductPage.titleMateriaux}>Matériaux</p>
				<p style={styleSheetProductPage.dataMateriaux}>{sheetData.infoMateriaux} </p>
			</div>

			<div style={styleSheetProductPage.recyclageContainer}>
				<p style={styleSheetProductPage.refText}>Réf: {sheetData.docId} / Réf log: {sheetData.refColis}</p>	
			</div>

			<p style={styleSheetProductPage.wordingVertical}>{sheetData.infoUsine}</p>

			

		</div>
	)
}

const styleSheetProductPage = {
	pdfbackground:{
		position: "absolute",
	    height: "1223px",
    	"z-index": "-1",
	},
	mainImgContainer: {
    	position: "absolute",
	    top: "40.5mm",
    	left: "35mm",
	    display: "flex",
    	"flex-direction": "row",
	    "justify-content": "center",
	    "align-items": "center",
		"width":"160mm",
		"height":"160mm",
  	},
 	brandLogoContainer: {
		position:"absolute",
		right:"35px",
		top:"25px"
  	},
	titleContainer: {
		position: "absolute",
	    left: "25mm",
    	top: "13mm",
		width:"143mm",
		height: "16mm",
	    display: "flex",
    	"justify-content": "space-evenly",
		"flex-direction":"column"
	},
	img2Container: {
		position:"absolute",
		bottom:"6mm",
		left:"38.5px",
		"border-top-right-radius": "20px",
		"border-bottom-right-radius": "20px",
		"z-index": "1",
		width:"74mm",
		height:"79mm",
		display:"flex",
		"align-items":"center",
		"justify-content":"center",
		"background-color":"white"
		// "box-shadow": "-5px 15px 10px rgba(150, 150, 150, 0.5)"
		
	},
	fakeShadow: {
		position: "absolute",
		bottom:"0mm",
		left:"38px",
		"z-index": "0",
		"border-bottom-left-radius": "20px",
	},
	plusProduitContainer:{
		position:"absolute",
		left:"90mm",
		bottom:"14.6mm",
		height: "64mm",
	    display: "flex",
    	"flex-direction": "column",
    	"align-items": "baseline",
		width:"120mm",
		gap: "10mm"
	},
	plusProduitTextContainer: {
		height: "5mm",
		width: "120mm",
		display: "flex",
		"align-items": "center"
	},
	dimensionContainer:{
		position:"absolute",
		bottom:"14.5mm",
		left:"85mm",
		height: "21mm",
		width: "56mm"
	},
	materiauxContainer:{
		position:"absolute",
		left:"151mm",
		bottom:"14.5mm",
		height: "21mm",
		width: "56mm"
	},
	recyclageContainer: {
		position:"absolute",
		bottom: "4mm",
	    right: "3mm",
		height: "3mm",
		width: "128mm",
		display: "flex",
		"justify-content": "flex-end"
	},
	wordingVertical:{
		position:"absolute",
		transform: "rotate(-90deg)",
		"transform-origin": "left",
	    bottom: "8mm",
	    left: "4.5mm",
	    color: "white",
		"font-family":"Lato-Medium",
		"font-size": "6pt",
		"font-weight": "regular",
	    "line-height": "7.2pt",
		height: "3mm",
		width: "240mm"
		
	},
	plusProduitText: {
		color:"white",
		"font-family":"Lato-Medium",
		"font-size": "13pt",
		"font-weight": "bold",
	    "line-height": "15.6pt"
	},
	refText: {
		"font-family":"Lato-Medium",
		"font-size": "7pt",
		"font-weight": "regular",
	    "line-height": "8.4pt",
		"margin-left":"28px",
		"margin-bottom":"2mm"
	},
	titleText:{
		"font-family":"Lucida-Grande",
		"font-size": "22pt",
	    "line-height": "26.4pt",
		"font-weight": "bold"
	},
	titleMateriaux: {
		"font-family":"Lato-Medium",
		"font-size": "13pt",
	    "line-height": "15pt",
		"font-weight": "bold"
	},
	dataMateriaux: {
		"font-family":"Lato-Medium",
		"font-size": "11pt",
	    "line-height": "15pt",
		"max-width":"485px",
		"font-weight": "regular"
	},
	titleDimension: {
		"font-family":"Lato-Medium",
		"font-size": "13pt",
	    "line-height": "15pt",
		"font-weight": "bold",
		"text-align": "right"
	},
	dataDimension: {
		"font-family":"Lato-Medium",
		"font-size": "11pt",
	    "line-height": "15pt",
		"text-align":"right",
		"font-weight": "regular"
	},
	pictoRecyclage:{
		height:"19.56mm",
		width:"60mm"
	}



	
}

export default ColorLabelIdomyaPage