/* eslint-disable jsx-a11y/alt-text */
import './rendering.css';
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2pdf from 'html2pdf.js';
import { Triangle } from '../utils/Triangle';

const axios = require("axios").default;

function ColorLabelIdomyaEssPage() {
	const [isLoaded, setIsLoaded] = useState(false);
	const [colorLabelData, setColorLabelData] = useState(null);
	const search = useLocation().search;

	function PrintPdf() {
		var listPageHtml = document.querySelectorAll('.pdfcontainerPortrait')
		listPageHtml.forEach((data, index) => {
			const productCode = colorLabelData.docIds[index].docId
			var options = {
				filename: productCode + "_colorLabelIdomyEssential.pdf",
				html2canvas: {
					useCORS: true,
					scale: 300 / 72,
				},
				jsPDF: {
					format: "a4",
					unit: "mm"
				}
			}
			html2pdf(data, options);

			console.log("print")
		})
	}

	useEffect(() => {

		const currentIds = new URLSearchParams(search).get('ids');
		const currentToken = new URLSearchParams(search).get('token');
		const currenLocale = new URLSearchParams(search).get('locale');
		//const today =  new Date().toLocaleString("fr-FR");
		document.title = "Color Label Idomya Essentials"

		axios.get("/api/colorLabel", { params: { token: encodeURIComponent(currentToken), ids: currentIds, locale: currenLocale, brand: "IDOMYA_E-" } }).then(resp => {
			if (resp.status == 200) {
				console.log("success", resp.data)
				setColorLabelData(resp.data)

			} else {
				console.log("error", resp.data)
			}
			setIsLoaded(true)
		}).catch(error => {
			console.log(error)
			setIsLoaded(true)
		})

	}, [])

	let dataPdf;


	if (isLoaded) {
		if (colorLabelData != null) {
			let listStructData = []



			const dataLength = colorLabelData.docIds.length
			for (let index = 0; index < dataLength; index++) {
				const element = colorLabelData.docIds[index];
				let last = false;
				if (index == dataLength - 1) {
					console.log("last")
					last = true;
				}

				listStructData.push(<ColorLabelIdomyaEssTemplate data={element} lastElement={last} />)

			}

			dataPdf = listStructData
		} else {
			dataPdf = <div className='pdfLoader'> Erreur dans la récupération des données</div>
		}
	} else {
		dataPdf = <div className='pdfLoader'>Récupération des données...<br />Veuillez Patienter</div>
	}

	return (
		<div style={{ margin: 0, display: "flex", flexDirection: "column", "max-width": "210mm" }}>
			<style>
				{`@media print {
	    		    .pdfnextPage {
	            		page-break-after: always;
	          		}	
  
	        		.pdfbuttonContainerCL {
	            		display: none !important;
	          		}
  
	        		.pdfcontainerBorder {
	            		border-bottom: 0px solid black !important;
	          		}
  
	          		.pdfcontainerPortrait {
	            		top: 0px !important;
	          		}
  
	          		@page {
			            margin: 0;
	    		        size: A4 portrait;
	          		}
  
			        body {
			            -webkit-print-color-adjust: exact;
	    			    color-adjust: exact;
	          		}
          		}`}
			</style>
			<div style={{ position: "inherit" }} class="pdfbuttonContainerCL">
				<button class="pdfprintPDFButton" onClick={PrintPdf}>
					Print Idomya Essential Label
				</button>
			</div>
			{dataPdf}

		</div>

	)
}

function ColorLabelIdomyaEssTemplate(props) {
	const sheetData = props.data
	const isLast = props.lastElement
	console.log(`url(${sheetData.logobrand})`)

	const nomProduitLines = sheetData.declinaison_nom_reecrit.split("\n").map(line => <p style={styleSheetProductPage.titleText}> {line.toUpperCase()} </p>)

	let infoBonusLines = sheetData.infoBonus.split("\n").map(line => <p style={styleSheetProductPage.DimensionDescriptif}> {line} <br /> </p>)


	useEffect(() => {
		console.log("parse data", isLast)
	}, [])

	return (
		<div style={{ top: "0px", border: "0px", margin: "0",  height: "296mm", width: "210mm"}} className={`pdfcontainerPortrait ${isLast == true ? "" : "pdfnextPage"}`}>
			
			<div style={styleSheetProductPage.mainImgContainer}>
				<img style={{  width: "197.8mm", height: "192mm","object-fit": "cover"}} src={sheetData.imgMontage1} />
				<div width="211mm" height="9mm" style={{width: "0mm",height: "0.25mm",position: "absolute",top: "-0.25mm",right: "-9mm","background-color": "rgb(128,196,188)", transform: "rotateZ(-3deg)"}}></div>
				<div style={styleSheetProductPage.triangleToplef}  width='213.4mm' height="9mm" ></div>
			</div>

			<div style={styleSheetProductPage.dimensionContainer}>
					<span style={styleSheetProductPage.DimensionDescriptif}>{infoBonusLines}</span>
			</div>

			<div style={{  top: "0px", border: "0px", margin: "0", position : "absolute" }}>
				<img style={{ height: "297mm", width: "210mm" }} src={"/assets/images/fondColor/CL_IDOMYA_ESSENTIALS_OK.png"}/>
			</div>

			<div style={styleSheetProductPage.brandLogoContainer}>
				{nomProduitLines}
			</div>

			<div style={styleSheetProductPage.plusProduitContainer}>
				<div style={styleSheetProductPage.plusProduitTextContainer}>
					<p style={styleSheetProductPage.plusProduitText}>{sheetData.infoPlus1}</p>
				</div>
				<div style={styleSheetProductPage.plusProduitTextContainer}>
					<p style={styleSheetProductPage.plusProduitText}>{sheetData.infoPlus2}</p>
				</div>
				<div style={styleSheetProductPage.plusProduitTextContainer}>
					<p style={styleSheetProductPage.plusProduitText}>{sheetData.infoPlus3}</p>
				</div>
			</div>

			<div style={styleSheetProductPage.recyclageContainer}>
				<p style={styleSheetProductPage.refText}>Réf : {sheetData.docId}</p>
				<p style={styleSheetProductPage.refText}>Réf log : {sheetData.refColis}</p>
			</div>

			<div style={styleSheetProductPage.infoUsineContainer}>
				<p style={styleSheetProductPage.refTextImport}>{sheetData.infoUsine}</p>
			</div>

		</div>
	)
}

const styleSheetProductPage = {
	mainImgContainer: {
		position: "absolute",
		top: "55mm",
		left: "0mm",
		display: "flex",
		"flex-direction": "row",
		"justify-content": "center",
		"align-items": "center",
	},
	brandLogoContainer: {
		position: "absolute",
	    // right: "12mm",
    	top: "30mm",
    	display: "flex",
		"margin-left": "11.5mm",
    	"flex-direction": "column",
    	"align-items": "flex-start",
    	height: "25mm",
		width: "181.5mm",
		"justify-content":"center"
	},
	triangleToplef: {
		"width": "0mm",
		"height": "0mm",
		position: "absolute",
		top: "-1mm",
		left: '0',
		"border-top": "10mm solid #80c4bc",
		"border-right": "206mm solid transparent",
	  },
	dimensionContainer: {
		position: "absolute",
		bottom: "34mm",
		right: "12mm",
		display: "flex",
		"flex-direction": "column",
		"justify-content": "center",
		"align-items": "flex-end",
		height: "17mm",
		width: "60mm"
	},
	infoBonusLines: {
		color: "#000000",
		"font-size": "15pt",
		"line-height": "22pt",
		"font-family": "Jost-Book",
	},
	plusProduitContainer: {
		position: "absolute",
		left: "10mm",
		bottom: "10mm",
		// height: "66mm",
		display: "flex",
		"flex-direction": "column",
		"align-items": "baseline",
		gap: "5.5mm"
	},
	DimensionDescriptif: {
		display: "flex",
		"flex-direction": "column",
		"align-items": "flex-end",
		"font-family": "Helvetica-Neue",
		"font-size": "8pt",
		color: "black",
		"line-height": "10pt",
		"font-weight": "regular",
		gap: "0.5mm"
	},
	materiauxContainer: {
		position: "absolute",
		left: "163mm",
		top: "345mm",
	},
	recyclageContainer: {
		position: "absolute",
		bottom: "3mm",
		right: "6.5mm",
		width: "72mm",
		height: "7mm"
	},
	plusProduitTextContainer: {
		height: "6mm",
		width: "124mm",
		display: "flex",
		"align-items": "center"
	},
	infoBonusLinesContainer: {
		position: "absolute",
		bottom: "10mm",
		left: "15mm",
		height: "25mm",
		width: "117.5mm",
		display: "flex",
		"align-items": "center",
	},
	plusProduitText: {
		color: "#000000",
		"font-family": "Helvetica-Neue",
		"font-size": "12pt",
		// "line-height": "114pt",
		"font-weight": "medium"
	},
	refText: {
		"font-family": "Helvetica-Neue",
		"font-size": "8pt",
		"line-height": "10pt",
		"text-align": "right",
		color: "black",
		"font-weight": "regular"
	},
	refTextImport: {
		"font-family": "Helvetica-Neue",
		"font-size": "8pt",
		"line-height": "10pt",
		color: "black",
		"font-weight": "regular"
	},
	titleText: {
		position: "relative",
		// width: "275mm",
		//height: "20mm",
		"font-family": "Lucida-Grande",
		"font-size": "30pt",
		"line-height": "36pt",
		"text-transform": "uppercase",
		color: "#FFFFFF",
		"font-weight": "bold"
	},
	titleMateriaux: {
		"font-family": "Helvetica-Neue",
		"font-size": "8pt",
		"line-height": "10pt",
		color: "black",
		"font-weight": "regular"
	},
	titleDimension: {
		"font-size": "8pt",
		"line-height": "10pt",
		"font-family": "Helvetica-Neue",
		color: "black",
		"font-weight": "regular"
	},
	dataDimension: {
		"font-size": "13pt",
		"font-family": "Jost-Book",
		color: "black"
	},
	infoUsineContainer: {
		position:" absolute",
		// right: "-54mm",
		left: "111.5mm",
		transform: "rotate(270deg)",
		top: "154mm",
		width: "183mm",
		height: "3mm"
	}
}

export default ColorLabelIdomyaEssPage