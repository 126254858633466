import './rendering.css';
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2pdf from 'html2pdf.js';

const axios = require("axios").default;



function ColorLabelJardiktJardiPage() {
	const [isLoaded, setIsLoaded] = useState(false);
	const [colorLabelData, setColorLabelData] = useState(null);
	const search = useLocation().search;

	function PrintPdf() {
		var listPageHtml = document.querySelectorAll('.pdfcontainerPortrait')
		listPageHtml.forEach((data, index) => {
			const productCode = colorLabelData.docIds[index].docId
			var options = {
				filename: productCode + "_colorLabelJardiktJardi.pdf",
				html2canvas: {
					useCORS: true,
					scale: 300 / 72,
				},
				jsPDF: {
					format: "a4",
					unit: "mm"
				}
			}
			html2pdf(data, options);

			console.log("print")
		})
	}

	useEffect(() => {

		const currentIds = new URLSearchParams(search).get('ids');
		const currentToken = new URLSearchParams(search).get('token');
		const currenLocale = new URLSearchParams(search).get('locale');
		//const today =  new Date().toLocaleString("fr-FR");
		document.title = "Color Label Jardikt Jardi"

		axios.get("/api/colorLabel", { params: { token: encodeURIComponent(currentToken), ids: currentIds, locale: currenLocale, brand: "JARDIKT", subBrand: "JARDINAGE" } }).then(resp => {
			if (resp.status == 200) {
				console.log("success", resp.data)
				setColorLabelData(resp.data)

			} else {
				console.log("error", resp.data)
			}
			setIsLoaded(true)
		}).catch(error => {
			console.log(error)
			setIsLoaded(true)
		})

	}, [])

	let dataPdf;


	if (isLoaded) {
		if (colorLabelData != null) {
			let listStructData = []



			const dataLength = colorLabelData.docIds.length
			for (let index = 0; index < dataLength; index++) {
				const element = colorLabelData.docIds[index];
				let last = false;
				if (index == dataLength - 1) {
					console.log("last")
					last = true;
				}

				listStructData.push(<ColorLabelJardiktJardiTemplate data={element} lastElement={last} />)

			}

			dataPdf = listStructData
		} else {
			dataPdf = <div className='pdfLoader'> Erreur dans la récupération des données</div>
		}
	} else {
		dataPdf = <div className='pdfLoader'>Récupération des données...<br />Veuillez Patienter</div>
	}

	return (
		<div style={{ margin: 0, display: "flex", flexDirection: "column" }}>
			<style>
				{`@media print {
	    		    .pdfnextPage {
	            		page-break-after: always;
	          		}	
  
	        		.pdfbuttonContainerCL {
	            		display: none !important;
	          		}
  
	        		.pdfcontainerBorder {
	            		border-bottom: 0px solid black !important;
	          		}
  
	          		.pdfcontainerPortrait {
	            		top: 0px !important;
	          		}
  
	          		@page {
			            margin: 0;
	    		        size: A4 portrait;
	          		}
  
			        body {
			            -webkit-print-color-adjust: exact;
	    			    color-adjust: exact;
	          		}
          		}`}
			</style>
			<div style={{ position: "inherit" }} class="pdfbuttonContainerCL">
				<button class="pdfprintPDFButton" onClick={PrintPdf}>
					Print Jardikt Color Label
				</button>
			</div>
			{dataPdf}

		</div>

	)
}

function ColorLabelJardiktJardiTemplate(props) {
	const sheetData = props.data
	const isLast = props.lastElement
	console.log(sheetData)

	const nomProduitLines = sheetData.declinaison_nom_reecrit.split("\n").map(line => <p style={styleSheetProductPage.titleText}> {line.toUpperCase()} </p>)

	const infoBonusLines = sheetData.infoBonus.split("\n").map(line => <p style={styleSheetProductPage.DimensionDescriptif}> {line} <br /> </p>)

	console.log(infoBonusLines)

	useEffect(() => {
		console.log("parse data", isLast)
	}, [])

	return (
		<div style={{ top: "0px", border: "0px", margin: "0", height: "296.5mm", width: "211mm" }} className={`pdfcontainerPortrait ${isLast == true ? "" : "pdfnextPage"}`}>

			<div style={{top: "0px", border: "0px", margin: "0", position: "absolute"}}>
				<img src={"/assets/images/fondColor/JARDIKT_JARDIN_OK.png"} style={{ height: "298mm", width: "211mm" }} alt="" />
			</div>

			<div style={styleSheetProductPage.mainImgContainer}>
				<div style={{ backgroundImage: `url(${sheetData.imgMontage1})`, backgroundSize: "cover", width: "195.6mm", height: "186mm", "background-repeat": "no-repeat", "background-position": "center"}}></div>
			</div>
			<div style={styleSheetProductPage.titreContainer}>
				{nomProduitLines}
			</div>

			<div style={styleSheetProductPage.img2Container}>
				<div style={{ backgroundImage: `url(${sheetData.img1})`, backgroundSize: "cover", width: "59mm", height: "58mm", "background-repeat": "no-repeat", "background-position": "center"}}></div>
			</div>

			<div style={styleSheetProductPage.plusProduitContainer}>
				<div style={styleSheetProductPage.plusProduitTextContainer}>
					<p style={styleSheetProductPage.plusProduitText}>{sheetData.infoPlus1}</p>
				</div>
				<div style={styleSheetProductPage.plusProduitTextContainer}>
					<p style={styleSheetProductPage.plusProduitText}>{sheetData.infoPlus2}</p>
				</div>
				<div style={styleSheetProductPage.plusProduitTextContainer}>
					<p style={styleSheetProductPage.plusProduitText}>{sheetData.infoPlus3}</p>
				</div>
			</div>

			<div style={styleSheetProductPage.dimensionContainer}>
				<p style={styleSheetProductPage.titleDimension}>Dimensions :  <span style={styleSheetProductPage.DimensionDescriptif}>(L) {sheetData.infoLength} x (l) {sheetData.infoWidth} x (H) {sheetData.infoHeight} cm</span></p>
				<p style={styleSheetProductPage.titleDimension}>Matériaux : <span style={styleSheetProductPage.DimensionDescriptif}>{sheetData.infoMateriaux}</span></p>
				<p style={styleSheetProductPage.DimensionDescriptif}>{infoBonusLines}</p>
			</div>

			{/* <div style={styleSheetProductPage.infoBonusLinesContainer}>
				<p style={styleSheetProductPage.infoBonusLines}>{infoBonusLines}</p>
			</div> */}

			<p style={styleSheetProductPage.wordingVertical}>{sheetData.infoUsine}</p>

			<div style={styleSheetProductPage.recyclageContainer}>
				<p style={styleSheetProductPage.refText}>Réf : {sheetData.docId}</p>
				<p style={styleSheetProductPage.refText}>Réf log : {sheetData.refColis}</p>
			</div>

		</div>
	)
}

const styleSheetProductPage = {
	mainImgContainer: {
		position: "absolute",
		top: "30mm",
		left: "0mm",
		display: "flex",
		"flex-direction": "row",
		"justify-content": "center",
		"align-items": "center",
	},
	titreContainer:{
		position: "absolute",
		right: "8mm",
		top: "8mm",
		width: "113mm",
		height: "15mm",
		display:"flex",
		"align-items":"center"
	},
	img2Container: {
		position: "absolute",
		bottom: "21mm",
		right: "15mm",
	},
	infoBonusLines: {
		color: "#FFFFFF",
		"font-size": "10pt",
		"line-height": "12pt",
		"font-family": "Futura Lt BT Light",
	},
	plusProduitContainer: {
		position: "absolute",
		left: "10mm",
		bottom: "23.5mm",
		display: "flex",
		"flex-direction": "column",
		"align-items": "baseline",
		gap: "8mm"
	},
	dimensionContainer: {
		position: "absolute",
		bottom: "4mm",
		left: "10mm",
		"flex-direction": "column",
		"align-items": "flex-start",
		"justify-content": "center",
		height: "15mm",
		width: "119mm",
		display: "flex"
	},
	DimensionDescriptif: {
		"font-family": "Lato-Medium",
		"font-size": "8pt",
		"line-height": "9.6pt",
		"font-weight":"regular",
		color: "#FFFFFF"
	},
	materiauxContainer: {
		position: "absolute",
		left: "163mm",
		top: "345mm",
	},
	recyclageContainer: {
		position: "absolute",
		bottom: "12mm",
		right: "14mm",
		width: "66mm",
		height: "5mm",
	},
	plusProduitTextContainer: {
		height: "12mm",
		width: "111mm",
		display: "flex",
		"align-items": "center"
	},
	infoBonusLinesContainer: {
		position: "absolute",
		bottom: "7mm",
		left: "20mm",
		height: "13mm",
		width: "100mm",
		display: "flex",
		"align-items": "center",
	},
	plusProduitText: {
		color: "white",
		"font-family": "Lato-Bold",
		"font-size": "16pt",
		"line-height": "19pt",
	},
	refText: {
		"font-family": "Lato-Medium",
		"font-weight": "regular",
		"font-size": "7pt",
		"line-height": "8.4pt",
		"text-align": "right",
		color: "#FFFFFF"
	},
	refTextImport: {
		"font-family": "Lato-Medium",
		"font-weight": "regular",
		"font-size": "7pt",
		"text-align": "right",
		"line-height": "8.4pt",
		color: "#FFFFFF"
	},
	titleText: {
		"font-family": "Lato-Black",
		"font-weight" : "800",
		"font-size": "20pt",
		"line-height": "24pt",
		"text-transform": "uppercase",
		color: "rgb(118,183,48)"
	},
	titleMateriaux: {
		"font-family": "Lato-Bold",
		"font-size": "8pt",
		"line-height": "9.6pt",

	},
	titleDimension: {
		"font-family": "Lato-Bold",
		"font-size": "8pt",
		"line-height": "9.6pt",
		color: "#FFFFFF"
	},
	dataDimension: {
		"font-size": "8pt",
		"line-height": "9.6pt",
		"font-family": "Lato-Medium",
		"font-weight": "regular",
		color: "#FFFFFF"
	},
	wordingVertical:{
		"font-family": "Lato-Medium",
		"font-weight": "regular",
		"font-size": "7pt",
		"line-height": "8.4pt",
		color: "#FFFFFF",
		position:"absolute",
		transform: "rotate(-90deg)",
		"transform-origin": "right",
	    bottom: "267mm",
	    left: "14mm",
		height: "2mm",
		width: "188mm"
	},
}

export default ColorLabelJardiktJardiPage